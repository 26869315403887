// @ts-strict-ignore
/* eslint-disable max-lines */
import Router from 'next/router';
import { captureException } from '@sentry/nextjs';
import actionTypes from 'src/modules/community/singlePost/actionTypes';
import { setAlert } from 'src/components/alert/actions';
import { enrichDataObject } from 'src/utils/enrichDataObject';
import i18n from 'src/utils/translate';
import { getShowSavePostModalPreference } from 'src/utils/showSavePostModalPreference';
import { showModal } from 'src/modules/shared/modal/actions';
import { ModalType } from 'src/constants/modalTypes';
import { del, post, put } from 'src/utils/api';
import { ReduxState } from 'src/store/store';

export const updatePostLock = (postId, communitySlug, isLocked) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_POST_LOCK });
    try {
      await put(`private/posts/${communitySlug}/${postId}/lock`, { isLocked });

      dispatch({
        type: actionTypes.UPDATE_POST_LOCK_SUCCESS,
        isLocked,
      });
      dispatch(setAlert(i18n.t('You turned on replying for this post.'), 'success'));
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UPDATE_POST_LOCK_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const updatePollLock = (postId, communitySlug, isLocked) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_POST_LOCK });
    try {
      await put(`private/polls/${communitySlug}/${postId}/lock`, { isLocked });

      dispatch({
        type: actionTypes.UPDATE_POST_LOCK_SUCCESS,
        isLocked,
      });
      dispatch(setAlert(i18n.t('You turned on replying for this poll.'), 'success'));
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UPDATE_POST_LOCK_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const togglePostPin = (postId, communitySlug) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_POST_PIN });
    try {
      const response = await post(`private/posts/${communitySlug}/${postId}/pin`);
      const { data: { isPinned } } = response;

      dispatch({
        type: actionTypes.UPDATE_POST_PIN_SUCCESS,
        isPinned,
        postId,
      });
      dispatch(setAlert(i18n.t(`Post has now been ${isPinned ? 'pinned' : 'unpinned'}!`), 'success'));
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UPDATE_POST_PIN_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const deletePostReply = (postId, superParentId, parentId, communitySlug, successMessage) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_POST_REPLY });
    try {
      await del(`private/posts/${communitySlug}/${parentId}/responses/${postId}`, {
        params: {
          superParentId,
        },
      });

      dispatch(setAlert(successMessage, 'success'));
      return dispatch({
        type: actionTypes.DELETE_POST_REPLY_SUCCESS,
        postId,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.DELETE_POST_REPLY_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const deletePost = (postId, communitySlug, successMessage) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_POST });
    try {
      await del(`private/posts/${communitySlug}/${postId}`);

      dispatch({ type: actionTypes.DELETE_POST_SUCCESS });
      dispatch(setAlert(successMessage, 'success'));
      Router.push(`/${communitySlug}`);
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.DELETE_POST_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const reportPost = ({ reason, comment, postId, communitySlug, onSuccess }) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.REPORT_POST });
    try {
      await post(`private/posts/${communitySlug}/${postId}/report`, { reason, comment });

      dispatch({ type: actionTypes.REPORT_POST_SUCCESS });
      onSuccess();
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.REPORT_POST_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const updatePostLike = (postId, communitySlug) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.LIKE_POST });
    try {
      const response = await post(`private/posts/${communitySlug}/${postId}/rate`);
      const { data: { isRated } } = response;
      dispatch({
        type: actionTypes.LIKE_POST_SUCCESS,
        isLiked: isRated,
        postId,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.LIKE_POST_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const followPost = (postId: number) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FOLLOW_POST });
    try {
      const response = await put(`private/posts/follow/${postId}`);
      if (response?.status !== 200) {
        dispatch(setAlert());
        return dispatch({ type: actionTypes.FOLLOW_POST_FAILURE });
      }

      if (getShowSavePostModalPreference()) {
        dispatch(showModal({ modalType: ModalType.SavePost }));
      }

      dispatch({ type: actionTypes.FOLLOW_POST_SUCCESS });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FOLLOW_POST_FAILURE });
      dispatch(setAlert());
    }
  };
};

export const unfollowPost = (postId: number) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.UNFOLLOW_POST });
    try {
      const response = await del(`private/posts/follow/${postId}`);
      if (response?.status !== 200) {
        dispatch(setAlert());
        return dispatch({ type: actionTypes.UNFOLLOW_POST_FAILURE });
      }
      dispatch({ type: actionTypes.UNFOLLOW_POST_SUCCESS });
      dispatch(setAlert(i18n.t('Post Unsaved!'), 'success'));
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UNFOLLOW_POST_FAILURE });
      dispatch(setAlert());
    }
  };
};

const uploadReplyImageFromFile = (file: Blob) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const { data: { imageHash } } = await post('private/images/response', formData);
      if (imageHash) dispatch({ type: actionTypes.REPLY_IMAGE_UPLOAD_SUCCESS, hash: imageHash });

    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.REPLY_IMAGE_UPLOAD_FAILURE, error: ex });
      if (ex.message.includes('413')) {
        dispatch(setAlert(i18n.t('Looks like your file exceeds our 10MB limit.')));
      } else {
        dispatch(setAlert(i18n.t('There was a problem uploading your image. Make sure your image ends with .jpg, .jpeg or .png.'), 'danger', 'none'));
      }
    }
  };
};

type RespondPostProps = {
  body: string,
  communitySlug: string,
  image?: { file?: Blob, url?: string, hash?: string, description?: string },
  mentionedUserIds?: number[],
  parentId?: number,
  postId: number,
  superParentId: number,
}

export const respondPost = ({
  postId, superParentId, body, communitySlug, mentionedUserIds = [], image = {},
}: RespondPostProps) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: actionTypes.REPLY_POST });

    if (image?.file) {
      await dispatch(uploadReplyImageFromFile(image.file));
    }
    const state = getState();
    const { imageHash, imageUploadError } = state.pages.singlePost.composeReply;

    if (imageUploadError) {
      dispatch({ type: actionTypes.REPLY_POST_FAILURE });
      return;
    }

    try {
      const { data } = await post(`private/posts/${communitySlug}/${postId}/responses`, {
        superParentId,
        body,
        mentionedUserIds,
        imageHash,
        imageDescription: image?.description || '',
      });

      dispatch(setAlert(i18n.t('New reply added!'), 'success'));
      await dispatch({
        type: actionTypes.REPLY_POST_SUCCESS,
        post: enrichDataObject(data, data.mentionedUsers),
        communitySlug,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.REPLY_POST_FAILURE, error: ex });
      dispatch(setAlert());
    }
  };
};

export const editPostReply = ({
  postId, superParentId, parentId, body, communitySlug, mentionedUserIds = [], image = {},
}: RespondPostProps) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: actionTypes.UPDATE_POST_REPLY });

    if (image?.file) {
      await dispatch(uploadReplyImageFromFile(image.file));
    }
    const state = getState();
    const { imageHash, imageUploadError } = state.pages.singlePost.composeReply;

    if (imageUploadError) {
      dispatch({ type: actionTypes.REPLY_POST_FAILURE });
      return;
    }

    try {
      const { data } = await put(`private/posts/${communitySlug}/${parentId}/responses/${postId}`, {
        superParentId,
        body,
        mentionedUserIds,
        ...(imageHash || image?.hash && { imageHash: imageHash || image.hash }),
        imageDescription: image?.description || '',
      });

      const post = enrichDataObject(data, data.mentionedUsers);

      dispatch(setAlert(i18n.t('Response edited successfully!'), 'success'));
      await dispatch({
        type: actionTypes.UPDATE_POST_REPLY_SUCCESS,
        post,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UPDATE_POST_REPLY_FAILURE, error: ex });
      dispatch(setAlert());
    }
  };
};

export const showSecondPageBlockMessageBox = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SHOW_SECOND_PAGE_BLOCK_MESSAGE_BOX });
  };
};
