/* eslint-disable max-lines */
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Modal as ReactModal } from 'reactstrap';
import { useDispatch } from 'src/store/store';
import i18n from 'src/utils/translate';
import { closeModal } from 'src/modules/shared/modal/actions';
import { CloseButton, HeaderButton, Header as StyledHeader, ModalTitle, ProgressBarContainer, ProgressText } from 'styles-js/modals';
import { FadeBottom } from 'styles-js/mixins/fade';
import Button, { ButtonType } from 'src/components/buttons/button';
import Icon from 'src/components/icon';
import ProgressBar from 'src/components/progressBar';
type modalProps = {
  acceptButtonDataTestId?: string;
  acceptButtonText?: string;
  acceptButtonType?: ButtonType;
  cancelButtonText?: string;
  children: ReactNode;
  customMaxWidth?: string;
  customHeader?: ReactNode;
  customFooter?: ReactNode;
  hasCloseButton?: boolean;
  horizontalButtons?: boolean;
  responsive?: boolean;
  title: string; // title required but can be bypassed with empty string, this is why we check that 'title' is truthy below
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
};
export default function DefaultModal({
  acceptButtonDataTestId = '',
  acceptButtonText,
  acceptButtonType,
  cancelButtonText,
  children,
  customMaxWidth,
  customHeader,
  customFooter,
  hasCloseButton = true,
  horizontalButtons = true,
  responsive = true,
  title,
  onAccept = () => {},
  onCancel = () => {},
  onClose = () => {}
}: modalProps) {
  const dispatch = useDispatch();
  const onAcceptClick = () => {
    dispatch(closeModal());
    onAccept();
  };
  const onCancelClick = () => {
    dispatch(closeModal());
    onCancel();
  };
  return <Modal customMaxWidth={customMaxWidth} labelledBy={title && 'modal-title'} responsive={responsive} onClose={onClose} data-sentry-element="Modal" data-sentry-component="DefaultModal" data-sentry-source-file="modal.tsx">
      {customHeader ? customHeader : hasCloseButton && <Header onClose={onClose} />}
      <Body data-sentry-element="Body" data-sentry-source-file="modal.tsx">
        {title && <ModalTitle id="modal-title">{title}</ModalTitle>}
        {children}
      </Body>
      {customFooter ? customFooter : (acceptButtonText || cancelButtonText) && <Footer horizontal={horizontalButtons}>
            {cancelButtonText && <Button type="link" onClick={onCancelClick}>{cancelButtonText}</Button>}
            {acceptButtonText && <Button dataTestId={acceptButtonDataTestId} type={acceptButtonType} onClick={onAcceptClick}>{acceptButtonText}</Button>}
          </Footer>}
    </Modal>;
}
type Modal = {
  children: ReactNode;
  customMaxWidth?: string;
  labelledBy?: string;
  responsive?: boolean;
  onClose?: () => void;
};
function Modal({
  children,
  customMaxWidth,
  labelledBy = '',
  responsive = true,
  onClose = () => {}
}: Modal) {
  const dispatch = useDispatch();
  const onCloseClick = () => {
    dispatch(closeModal());
    onClose();
  };
  const Container = responsive ? ResponsiveModal : FullscreenModal;
  return <Container $customMaxWidth={customMaxWidth} backdrop="static" // modal can't be dismissed by clicking away from it
  backdropClassName="app-modal__backdrop" isOpen={true} labelledBy={labelledBy} toggle={onCloseClick} data-sentry-element="Container" data-sentry-component="Modal" data-sentry-source-file="modal.tsx">
      {children}
    </Container>;
}

// To use for multi-screen modals only
export { Modal as MultiScreenModal };
type Header = {
  hasCloseButton?: boolean;
  progress?: number;
  onBack?: () => void;
  onClose?: () => void;
};

// To use in modal screens
export const Header = ({
  hasCloseButton = true,
  progress,
  onBack,
  onClose = () => {}
}: Header) => {
  if (!hasCloseButton && !onBack && !progress) return null;
  return <StyledHeader data-sentry-element="StyledHeader" data-sentry-component="Header" data-sentry-source-file="modal.tsx">
      {onBack && <HeaderButton data-testid="modal-back-button" onClick={onBack}>
          <Icon icon="open-left" />{i18n.t('Back')}
        </HeaderButton>}
      {hasCloseButton && <Close onClose={onClose} />}
      {progress && progress >= 0 && <>
          <ProgressText>{i18n.t('Progress')}{' '}{progress}%</ProgressText>
          <ProgressBarContainer>
            <ProgressBar colour={'green'} heightSize={4} progress={progress} />
          </ProgressBarContainer>
        </>}
    </StyledHeader>;
};
type Close = {
  showText?: boolean;
  onClose?: () => void;
};
export const Close = ({
  showText = true,
  onClose = () => {}
}: Close) => {
  const dispatch = useDispatch();
  const onCloseClick = () => {
    dispatch(closeModal());
    onClose();
  };
  return <CloseButton data-testid="modal-close-button" onClick={onCloseClick} data-sentry-element="CloseButton" data-sentry-component="Close" data-sentry-source-file="modal.tsx">
      {showText && i18n.t('Close')}
      <Icon icon="cross" data-sentry-element="Icon" data-sentry-source-file="modal.tsx" />
    </CloseButton>;
};

// Overwriting reactstrap modal component
const ModalComponent = styled(ReactModal)<{
  $customMaxWidth?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: none;
  height: 100%;
  margin: auto;
  .modal-content {
    max-width: ${({
  $customMaxWidth
}) => $customMaxWidth ? $customMaxWidth : '560px'};
    border-radius: 8px;
    border: none;
    overflow: hidden;
    max-height: 90vh;
  }
  .modal-content img { // Google Ads
    max-width: 100%;
  }
`;
const FullscreenModal = styled(ModalComponent)`
  .modal-content {
    @media (max-width: ${({
  theme
}) => theme.screenXsMin}) {
      height: 100%;
      max-height: 100vh;
      border-radius: 0;
    }
    @media (max-height: ${({
  theme
}) => theme.screenSmMinHeight}) {
      height: 100%;
      max-height: 100vh;
      border-radius: 0;
    }
  }
`;
const ResponsiveModal = styled(ModalComponent)`
  .modal-content {
    margin: auto 20px;
    @media (${({
  theme
}) => theme.underScreenXxsmall}) {
      margin: auto 10px;
    }
  }
`;
export const Body = styled.div.attrs<{
  'data-testid'?: string;
}>({
  'data-testid': 'modal-body'
})`
  flex: 1;
  padding: 40px;
  overflow-y: auto;
  @media (max-width: ${({
  theme
}) => theme.screenXsMin}) {
    padding: 20px;
  }
`;

// To use in the following scenarios: in modal screens, or when buttons have the 'disabled' attribute, or when buttons are part of a form
export const Footer = styled.div.attrs<{
  horizontal?: boolean;
}>(({
  horizontal
}) => ({
  'data-testid': 'modal-footer',
  horizontal: horizontal === false ? false : true
}))<{
  horizontal?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  background-color: ${({
  theme
}) => theme.colorBlueBackground};
  ${FadeBottom};
  @media (max-width: ${({
  theme
}) => theme.screenXsMin}) {
    ${({
  horizontal
}) => !horizontal && css`
      flex-direction: column-reverse;
    `}
  }

  button {
    margin: 0;
    padding: 15px 20px;
    @media (max-width: ${({
  theme
}) => theme.screenXsMin}) {
      ${({
  horizontal
}) => !horizontal && css`
        width: 100%;
        padding: 6px 12px;
      `}
    }
    @media (${({
  theme
}) => theme.underScreenXxsmall}) {
      padding: 6px 12px;
    }
  }
`;