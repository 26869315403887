const articles = { // these keys are support.healthunlocked.com slugs for specific help scout articles
  '11-community-guidelines': '5cf62b5a04286333a26421db',
  '17-how-do-i-report-content': '5cf639472c7d3a38371311a9',
  '21-why-was-replying-turned-off': '5cf641d82c7d3a3837131217',
  '25-how-do-i-mute-a-user-who-is-private-messaging-me': '5cf643aa2c7d3a383713123a',
  '36-turn-off-replying-on-posts': '5cf64a992c7d3a38371312b5',
  '37-how-do-i-add-google-analytics-tracking-to-my-community': '5cf64c6604286333a26423ca',
  '39-what-does-following-a-community-mean': '5cf64f702c7d3a38371312f7',
  '42-community-campaigns': '5cf6514704286333a26423fe',
  '43-what-happens-when-i-close-my-account-with-healthunlocked': '5cf651a52c7d3a383713131a',
  '55-what-happens-when-i-remove-a-post': '5cfe76ae04286333a2646149',
  '69-what-is-the-follow-feature': '5cfe7d3b04286333a264619b',
  '71-why-am-i-being-asked-to-sign-in-every-time-i-access-healthunlocked': '5cfe7f292c7d3a38371353ef',
  '78-moderating-your-community': '5cfe812c2c7d3a3837135409',
  '79-creating-community-polls': '5cfe81b42c7d3a383713540c',
  '84-how-do-i-assign-a-badge-to-a-community-member': '5cfe84472c7d3a3837135437',
  '85-how-to-resolve-duplicate-words-when-writing-or-replying-to-a-post': '633193c19c3e7c7d6a1ac672',
  '87-community-metrics': '5cfe851804286333a2646205',
  '99-how-to-control-your-privacy-on-healthunlocked': '5d49a4db0428635a6ec89819',
  '101-your-profile-on-healthunlocked': '5d4c52882c7d3a036965cdbd',
  '102-sentiment-feedback': '5d5c02392c7d3a7920be4421',
  '107-what-happens-when-i-report': '5dc5463b04286364bc9181e2',
  '109-why-my-youtube-video-is-not-playing': '5e6270082c7d3a7e9ae8cb6e',
  '118-explore-recommendations': '5e6b99db2c7d3a7e9ae92565',
  '130-why-has-someone-followed-me': '5ed8fb712c7d3a10cba85e9c',
  '134-community-description': '5f0ce0c12c7d3a10cbaa8fe0',
  '139-different-types-of-search-on-healthunlocked': '5f6881bec9e77c001603aff2',
  '142-images-hidden-by-default': '5f87485e4cedfd0017dd1c4b',
  '143-options-when-replying-to-posts': '5f87490bc9e77c0016217d60',
  '145-community-guidelines-highlights': '5ff32598b5efec03af3ef9c2',
  '147-terms': '60241fa9a4cefb30ae5c9902',
  '148-privacy': '602422356867724dfc6f1d7c',
  '149-terms-for-community-partners': '6024252dac2f834ec538791f',
  '150-cookies': '602428a8a4cefb30ae5c9950',
  '151-security': '60245eb4ac2f834ec5387b3c',
  '154-opportunities-by-email': '602bb51a8502d1120e907d8c',
  '153-updates-to-privacy-policy-and-term-of-use': '602a86408502d1120e9077d8',
  '158-different-types-of-badges-on-healthunlocked': '602f9574b3ebfb109b584028',
  '172-what-are-archived-communities': '60a3aee427c86942e8069006',
  '173-what-are-hidden-accounts': '60b65c79c1410a601d9aefa9',
  '174-why-are-you-asking-about-my-ethnicity': '60c084e54173c622df929e4d',
  '175-why-are-you-asking-about-my-country-and-state-region': '60c86719b48f6d2c7543575b',
  '176-why-can-i-only-change-my-username-twice': '60cccd1461c60c534bd67646',
  '184-why-didnt-i-receive-a-password-reset-email': '6310c27590c29a3d732c3e61',
  '189-understanding-email-notifications-for-polls': '63da4d34aaf47a675380b91d',
  '190-posting-tips': '63ff6dca0b394c459d8a6d80',
  '191-who-can-see-my-post': '6408677716d5327537bcb66c',
  '192-suggested-tags': '640867d4c6ff3e6ff7fa7b94',
  '193-communities-moderated-by-healthunlocked': '6425bc748fe95055b525a0a9',
  '194-community-support-information': '645e65fcd3751e29d855998c',
  '205-code-of-conduct-for-moderation-team-members': '64d389c087bc8b31f2cba1de',
  '210-recruiting-and-managing-ambassadors': '65d4c38913abf5310a20ba4c',
  '211-organic-traffic-for-community-growth': '65d4c4bc3a6a1c55084bfc61',
  '212-community-metrics-calculations': '65dc98e3e658ed1a52194f4f',
  '213-how-to-set-up-a-community-campaign': '66d07ebc082392452a07a288',
};

export default articles;

export type articleType = keyof typeof articles;
